import axios from "axios";
import { browserName } from "react-device-detect";
import { useStore } from "./store";

type Response = {
  Error: string;
  Result: boolean;
};

export type MerchantNames = "btech" | "kazyon" | "metro" | "zaman";
type Company = {
  logo: string;
  id: MerchantNames;
};

export const companies: Company[] = [
  {
    logo: "images/zaman.png",
    id: "zaman",
  },
  {
    logo: "images/metro.png",
    id: "metro",
  },
  {
    logo: "images/btech.png",
    id: "btech",
  },
  {
    logo: "images/kazyon.png",
    id: "kazyon",
  },
];

const CountryID = 1;
const CloneId = 62;
const register_token = "V5VWw4OdL0HI7lW4KrOxyVOHA16Zo5v1clpn/tAz13cuMy9B4+2ZdLPO1Tz0SdFl3ZQvHckrX1VDEZjLmcdlpcYDBKNBgGPp";

export const GOOGLE_API_KEY = "AIzaSyDCb9MTnNOwLqkMiW4wIAp-Mtw2HladLTE";

const api = axios.create({
  baseURL: "https://ws.e-points.net/EpointsMobileService/EpointsMobileService",
});

export const _getIPAddress = async () => {
  const res = await axios.get("https://api.ipify.org/?format=json");
  return res.data.ip || "";
};
export const _getAddress = async (lat?: string, lng?: string) => {
  const { data } = await axios.get(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_API_KEY}`
  );

  return data.results[0].formatted_address || "";
};

export const _copyToClipboard = async (text: string) => {
  if ("clipboard" in navigator)
    return await navigator.clipboard.writeText(text);

  return document.execCommand("copy", true, text);
};

export const _openWithGoogleMaps = (lat: string, lng: string) => {
  window.open(
    `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`,
    "_blank"
  );
};

export const _register = async (body: {
  FirstName: string;
  LastName: string;
  Latitude: string;
  Longitude: string;
  MobileNo: string;
  ShopName: string;
  City: string;
  Region: string;
  ChkCalls: string;
  ChkNews: string;
  ChkSMS: string;
}) => {
  useStore.getState().SetLoading(true);
  const IPAddress = await _getIPAddress();
  const { data } = await api.post<Response>("/RegisterForm", {
    ...body,
    CloneId,
    CountryID,
    Explorer: browserName,
    IPAddress,
    Token: register_token,
  });

  if (data.Result) useStore.getState().SetMobileNo(body.MobileNo);

  useStore.getState().SetLoading(false);
  return data;
};

export const _login = async (body: { MobileNo: string }) => {
  useStore.getState().SetLoading(true);
  const { data } = await api.post<Response>("/LoginForm", {
    ...body,
    CloneId,
    CountryID,
    OTPOnly: false,
  });

  if (data.Result) useStore.getState().SetMobileNo(body.MobileNo);

  useStore.getState().SetLoading(false);
  return data;
};

export const _validateOTP = async (body: {
  MobileNo: string;
  OTP: string;
  isLogin: boolean;
}) => {
  useStore.getState().SetLoading(true);
  const { data } = await api.post<
    Response & {
      Balance: string;
      CardholderId: string;
      Currency: string;
      Token: string;
    }
  >("/ValidateOTPForm", {
    ...body,
    CloneId,
    CountryID,
    MobileNo: useStore.getState().MobileNo,
  });

  if (data.Result)
    useStore.getState().Login({
      MobileNo: useStore.getState().MobileNo,
      Balance: data.Balance,
      Token: register_token,
      Currency: data.Currency,
    });

  useStore.getState().SetLoading(false);
  return data;
};

export const _resendCode = async () => {
  useStore.getState().SetLoading(true);
  const { data } = await api.post<Response>("/LoginForm", {
    CloneId,
    CountryID,
    MobileNo: useStore.getState().MobileNo,
    OTPOnly: true,
  });

  useStore.getState().SetLoading(false);
  return data;
};

export const _getBalance = async () => {
  useStore.getState().SetLoading(true);
  const { data } = await api.post<
    Response & {
      Balance: string;
      CardholderId: string;
      Currency: string;
    }
  >("/GetBalanceForm", {
    CloneId,
    CountryID,
    MobileNo: useStore.getState().MobileNo,
    Token: useStore.getState().Token,
  });

  if (data.Result) useStore.getState().UpdateBalance({ Balance: data.Balance });

  useStore.getState().SetLoading(false);
  return data;
};

export const _getMerchantBranches = async (body: {
  Merchant: MerchantNames;
}) => {
  let MerchantId: string;
  switch (body.Merchant) {
    case "btech":
      MerchantId = "1430";
      break;
    case "kazyon":
      MerchantId = "1432";
      break;
    case "metro":
      MerchantId = "3134";
      break;
    case "zaman":
      MerchantId = "2992";
      break;
  }

  const { data } = await api.post<
    Response & {
      Message: string;
      merchantDetails: {
        BranchAddress: string;
        BranchId: string;
        BranchName: string;
        CityName: string;
        Latitude: string;
        LogoImage: string;
        Longitude: string;
      }[];
    }
  >("/GetMerchantDetailForm", {
    MerchantId,
    CloneID: CloneId,
    Token: register_token,
  });

  return data;
};

export const _getAllMerchantBranches = async () => {
  const currentMerchants = useStore.getState().MerchantBranches;

  if (currentMerchants.length === 0) {
    const btech = await _getMerchantBranches({ Merchant: "btech" });
    if (btech.Result)
      useStore.getState().SetMerchantBranches({
        merchant: "btech",
        branches: btech.merchantDetails,
      });

    const kazyon = await _getMerchantBranches({ Merchant: "kazyon" });
    if (btech.Result)
      useStore.getState().SetMerchantBranches({
        merchant: "kazyon",
        branches: kazyon.merchantDetails,
      });

    const metro = await _getMerchantBranches({ Merchant: "metro" });
    if (btech.Result)
      useStore.getState().SetMerchantBranches({
        merchant: "metro",
        branches: metro.merchantDetails,
      });

    const zaman = await _getMerchantBranches({ Merchant: "zaman" });
    if (btech.Result)
      useStore.getState().SetMerchantBranches({
        merchant: "zaman",
        branches: zaman.merchantDetails,
      });
  }
};

export const _addCode = async (body: { Code: string }) => {
  const IPAddress = await _getIPAddress();
  const { data } = await api.post<
    Response & {
      Balance: string;
      Messsage: string;
    }
  >("/AddCodeForm", {
    ...body,
    Explorer: browserName,
    IPAddress,
    CountryID,
    ShortCode: 2653,
    MobileNo: useStore.getState().MobileNo,
    Token: useStore.getState().Token,
  });

  return data;
};

const _blockUser = async () => {
  const { data } = await api.post<Response>("/BlockUserForm", {
    CloneId,
    CountryID,
    MobileNo: useStore.getState().MobileNo,
    Token: useStore.getState().Token,
  });
  return data;
};

/**
 * - 1 or 2 codes 1st and 2nd invalid code attempts warning 3rd attempt block
 * - 3 codes or more:
 *  <=25% show invalid codes only no warning
 *  > 25% & < 75% warning message if the second attempt same el percentage block
 *  Greater than 75% blocked
 */
export const _addCodes = async (codes: string[]) => {
  useStore.getState().SetLoading(true);
  let warning = false;
  let blocked = false;
  const { AddCodeAttempts, IncrementAddCodeAttempts, ResetAddCodeAttempts } =
    useStore.getState();

  const statuses = await Promise.all(codes.map((Code) => _addCode({ Code })));

  const invalidCodes = [];
  const validCodesIndexes = [];

  statuses.forEach((res, index) => {
    if (!res.Result) {
      invalidCodes.push({ value: codes[index], error: true });
      if (res.Messsage.includes("محظور")) blocked = true;
    } else {
      validCodesIndexes.push(index);
    }
  });

  const invalidCodesCount = invalidCodes.length;
  const invalidCodesPercentage = (invalidCodesCount / codes.length) * 100;

  if (invalidCodesCount > 0 && invalidCodesCount === codes.length) {
    if (codes.length < 3) {
      if (AddCodeAttempts < 2) {
        IncrementAddCodeAttempts();
        warning = true;
      } else {
        ResetAddCodeAttempts();
        await _blockUser();
        blocked = true;
      }
    } else {
      if (invalidCodesPercentage > 25 && invalidCodesPercentage < 75) {
        if (AddCodeAttempts < 1) {
          IncrementAddCodeAttempts();
          warning = true;
        } else {
          ResetAddCodeAttempts();
          await _blockUser();
          blocked = true;
        }
      } else if (invalidCodesPercentage >= 75) {
        ResetAddCodeAttempts();
        await _blockUser();
        blocked = true;
      }
    }
  }

  await _getBalance();
  await _getTransactionsHistory(true);

  useStore.getState().SetLoading(false);
  return {
    statuses: statuses,
    invalidCodes,
    invalidCodesCount,
    validCodesIndexes: [0],
    validCodesCount: codes.length - invalidCodesCount,
    warning,
    blocked,
  };
};

const _selectVoucher = async (body: {
  ChargingMobileNo?: string;
  OfferId: number;
  SelectedValue: string;
}) => {
  if (!body.OfferId || !body.SelectedValue || body.SelectedValue === "") {
    return {
      Result: false,
      Message: "Error",
      VoucherNo: "",
      Balance: "",
    };
  }

  useStore.getState().SetLoading(true);
  const { data } = await api.post<
    Response & {
      Balance: string;
      Message: string;
      VoucherNo: string;
    }
  >("/SelectVoucher", {
    ...body,
    FullName: "",
    Reference: "",
    LanguageID: 1,
    CloneID: CloneId,
    CountryID,
    MobileNo: useStore.getState().MobileNo,
    Token: useStore.getState().Token,
  });

  // if (data.Result)
  await _getBalance();
  await _getTransactionsHistory(true);

  useStore.getState().SetLoading(false);
  return data;
};

export const _redeem = async (body: {
  SelectedValue: string;
  Merchant: MerchantNames;
}) => {
  let OfferId: number;
  switch (body.Merchant) {
    case "btech":
      OfferId = 260;
      break;
    case "kazyon":
      OfferId = 263;
      break;
    case "metro":
      OfferId = 262;
      break;
    case "zaman":
      OfferId = 261;
      break;
  }

  const data = await _selectVoucher({
    OfferId,
    SelectedValue: body.SelectedValue,
  });

  return data;
};

export const _recharge = async (body: {
  ChargingMobileNo?: string;
  SelectedValue: string;
  Operator: "orange" | "vodafone" | "etisalat" | "we";
}) => {
  let OfferId: number;
  switch (body.Operator) {
    case "orange":
      OfferId = 266;
      break;
    case "vodafone":
      OfferId = 264;
      break;
    case "etisalat":
      OfferId = 265;
      break;
    case "we":
      OfferId = 258;
      break;
  }

  const data = await _selectVoucher({
    OfferId,
    ChargingMobileNo: body.ChargingMobileNo || "",
    SelectedValue: body.SelectedValue,
  });

  return data;
};

export const _getTransactionsHistory = async (update = false) => {
  const MobileNo = useStore.getState().MobileNo;

  if (MobileNo) {
    const { data } = await api.post<
      Response & {
        HistoryDetails: {
          Amount: string;
          Description: string;
          Logo: string;
          TransDate: string;
          TransType: string;
        }[];
      }
    >("/GetHistoryForm", {
      CloneID: CloneId,
      MobileNo: useStore.getState().MobileNo,
      Token: useStore.getState().Token,
    });

    if (data.Result && data.HistoryDetails.length > 0)
      useStore.getState().UpdateHistory(data.HistoryDetails);
  }
};

export default api;
